<template>
  <b-nav-item-dropdown
      right
      toggle-class="d-flex align-items-center dropdown-user-link"
      class="dropdown-user"
  >
    <template #button-content>
      <div class="d-sm-flex d-none user-nav">
        <p class="user-name font-weight-bolder mb-0" style="font-size: 9px!important;">
          {{ myName }}
        </p>
        <span class="user-status">{{ myRoleName }}</span>
      </div>
      <!--TODO: Implement avatar, add: :src="userData.avatar"-->
      <b-avatar
          size="40"
          variant="light-primary"
          badge
          class="badge-minimal"
          badge-variant="success"
      >
        <feather-icon
            v-if="!myName"
            icon="UserIcon"
            size="22"
        />
      </b-avatar>
    </template>
    <b-dropdown-divider/>

    <b-dropdown-item
        :to="{ path: '/organization/users' }"
        link-class="d-flex align-items-center"
    >
      <feather-icon
          size="16"
          icon="SettingsIcon"
          class="mr-50"
      />
      <span>Settings</span>
    </b-dropdown-item>

    <b-dropdown-item
        link-class="d-flex align-items-center"
        @click="logout"
    >
      <feather-icon
          size="16"
          icon="LogOutIcon"
          class="mr-50"
      />
      <span>Logout</span>
    </b-dropdown-item>
  </b-nav-item-dropdown>
</template>

<script>
import {
  BNavItemDropdown, BDropdownItem, BDropdownDivider, BAvatar,
} from 'bootstrap-vue'
import {initialAbility} from '@/libs/acl/config'
import {avatarText} from '@core/utils/filter'
import {mapActions, mapGetters} from 'vuex'

export default {
  data() {
    return {
      userData: JSON.parse(localStorage.getItem('userData')),
      avatarText,
    }
  },
  computed: {
    ...mapGetters('user', ['myName', 'myRoleName']),

  },
  methods: {
    ...mapActions('user', {_logout: 'logout'}),

    logout() {
      this._logout().finally(() => {
        console.log('doing logout')
        // Reset ability
        this.$ability.update(initialAbility)
        // Redirect to login page
        this.$router.push({name: '/login'})
      })
    },
  },
}
</script>
