<template>
  <div>

    <b-nav-item-dropdown
        id="dropdown-grouped"
        variant="link"
        class="dropdown-language"
        right
    >
      <template #button-content>
        <country-flag :country="currentLocale.country" class="opacity-8"/>
        <span class="ml-50 text-body m-0" style="vertical-align: middle">{{ currentLocale.label }}</span>
      </template>
      <b-dropdown-item
          v-for="locale in locales"
          :key="locale.locale"
          @click="getLang(locale)"
      >
        <country-flag :country="locale.country" class="opacity-8 m-0" style="vertical-align: middle"/>
        <span class="ml-50">{{ locale.label }}</span>
      </b-dropdown-item>
    </b-nav-item-dropdown>

  </div>
</template>

<script>
import {mapActions, mapGetters, mapMutations} from 'vuex'
import CountryFlag from 'vue-country-flag'
import useAppCustomizer from '@core/layouts/components/app-customizer/useAppCustomizer'
import {localize} from 'vee-validate';

export default {
  components: {
    CountryFlag
  },
  // ! Need to move this computed property to comp function once we get to Vue 3
  computed: {
    ...mapGetters('translator', ['currentLang']),
    ...mapGetters('translator', {currentLang: 'getCurrentLang'}),
    locales() {
      return [
        {
          locale: 'de',
          locale_full: 'de',
          label: 'Deutsch',
          country: 'AT',
        },
        {
          locale: 'en',
          locale_full: 'en',
          label: 'English',
          country: 'GB',
        }, {
          locale: 'ru',
          locale_full: 'ru',
          label: 'Russian',
          country: 'RU',
        },
        /* {
           locale: 'es',
           locale_full: 'es',
           label: 'Español',
           country: 'ES'
         },
         {
           locale: 'fr',
           locale_full: 'fr',
           label: 'Français',
           country: 'FR'
         },
         {
           locale: 'he',
           locale_full: 'he',
           label: 'עברית',
           country: 'IL',
           rtl:true,
         },
         {
           locale: 'pt',
           locale_full: 'pt_BR',
           label: 'Portugues',
           country: 'BR'
         }*/
      ]
    },
    currentLocale() {
      return this.locales.find(l => l.locale === this.currentLang) || this.locales.find(l => l.locale === 'en')
    },
  },
  created() {
    this.getLang();
    this.$root.$on('crm::lang-updated', this.setLangProfiles)
  },
  beforeDestroy() {
    this.$root.$off('crm::lang-updated', this.setLangProfiles)
  },
  methods: {
    ...mapMutations('appConfig', ['TOGGLE_RTL']),
    ...mapMutations('translator', {_getLang: 'setLanguage'}),
    getLang(language) {
      language = language || {};
      this._getLang(language.locale);
    },
    setLangProfiles() {
      let $this = this;
      let language = this.locales.find(l => l.locale === $this.currentLang)
      this.$moment.locale(language.locale)
      this.TOGGLE_RTL(language.rtl === true);
      localize(language.locale_full);
    }
  },
}
</script>

<style>

</style>
